export default [
  {
    key: "objectiveTypeId",
    label: "field.objectiveType",
    rules: "required",
    type: "asyn-single-selection",
    repository: "objectiveType",
    selectionKey: "id",
    selectionLabel: "titleEn",
  },
  {
    key: "weight",
    label: "field.weight",
    rules: "required|integer",
    type: "text",
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "max:300",
    type: "textarea",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "max:300",
    type: "textarea",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "max:300",
    type: "textarea",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "max:300",
    type: "textarea",
  },
  {
    label: "breadcrumb.objectiveKeyResult",
    icon: "ClipboardIcon",
    type: "divider",
  },
  {
    key: "objectiveKeyResults",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
