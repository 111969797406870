<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #objectiveKeyResults>
              <div class="pt-2">
                <b-row>
                  <b-col
                    lg="12"
                    v-for="(keyResult, idx) in data.objectiveKeyResults"
                    :key="`keyResult-${idx}`"
                  >
                    <b-row>
                      <b-col cols="12" md="9" lg="10">
                        <validation-provider
                          #default="{ errors }"
                          :vid="`${keyResult.title}-${idx}`"
                          name="field.title"
                          rules="required"
                        >
                          <label>{{ $t("field.title") }}</label>
                          <b-form-textarea
                            :placeholder="$t('field.title')"
                            autocomplete="off"
                            rows="2"
                            max-rows="6"
                            v-model="keyResult.title"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="3" lg="2" align-self="end" class="text-right mt-2">
                        <b-button variant="outline-danger" @click="remove(idx)">
                          <feather-icon
                            icon="XIcon"
                            class="mr-1"
                          ></feather-icon>
                          <span>{{ $t("button.delete") }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                    <hr class="mb-2" />
                  </b-col>
                  <b-col cols="12">
                    <b-button :disabled="data.objectiveKeyResults.length >= maxLength" variant="primary" class="mb-2" @click="addMore">
                      <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                      <span>{{ $t("button.addMore") }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                v-if="$can('create', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const ObjectiveRepository = Repository.get("objective");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        objectiveTypeId: null,
        titleEn: null,
        titleKm: null,
        descriptionEn: null,
        descriptionKm: null,
        weight: null,
        objectiveKeyResults: [],
        isEnable: true,
      },
      loading: false,
      maxLength: 5,
    };
  },
  methods: {
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      ObjectiveRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    addMore() {
      let data = {
        title: null,
      };
      this.data.objectiveKeyResults.push(data);
    },
    remove(index) {
      this.data.objectiveKeyResults.splice(index, 1);
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "objective";
    const route = "objective";

    return { fields, resource, route };
  },
};
</script>
